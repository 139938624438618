<template>
  <div>
    <div>
      <b-row class="match-height">
        <b-col
          v-for="(assignment, i) in assignments"
          :key="i"
          lg=""
          md="4"
          sm="6"
          @click="handleActiveAssignment(assignment.statisticTitle)"
        >
          <Assignment
            :icon="assignment.icon"
            :statistic="assignment.statistic"
            :statistic-title="assignment.statisticTitle"
            :color="assignment.color"
            :select-assignment="selectAssignment"
          />
        </b-col>
      </b-row>
    </div>
    <assignments-table
      @custom-event-getAssignment="reloadPage"
      :assignments="items"
      v-if="items"
    />
  </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue"
import axios from "@/libs/axios"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import Assignment from "../engagement/Assignment.vue"
import AssignmentsTable from "./AssignmentsTable.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BRow,
    BCol,
    AssignmentsTable,
    Assignment,
  },
  data: () => ({
    selectAssignment: "Active",
    assignments: [],
    items: [],
    message: "",
  }),
  mounted() {
    this.getAssignmentsCount()
    this.getAssignments("filter[status]=Active")
  },
  methods: {
    reloadPage() {
      this.getAssignmentsCount()
      this.getAssignments("filter[status]=Active")
    },
    handleActiveAssignment(statisticTitle) {
      if (this.selectAssignment !== statisticTitle) {
        this.selectAssignment = statisticTitle
        if (this.selectAssignment === "New") {
          this.getAssignments("filter[status]=New")
        }
        if (this.selectAssignment === "Payment Due") {
          this.getAssignments("filter[status]=Payment Due")
        }
        if (this.selectAssignment === "Archived") {
          this.getAssignments("filter[status]=Archieved")
        }
        if (this.selectAssignment === "Active") {
          this.getAssignments("filter[status]=Active")
        }
        if (this.selectAssignment === "Completed") {
          this.getAssignments("filter[status]=Complete")
        }
      }
    },
    async getAssignments(data) {
      axios
        .get(`/assignments/filter?${data}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Assignment List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Assignment List!`,
            },
          })

          this.items = res.data
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    async getAssignmentsCount() {
      axios
        .get(`/assignments/filterCount`)
        .then((res) => {
          this.assignments = [
            {
              color: "primary",
              icon: "ServerIcon",
              statistic: res.data.new,
              statisticTitle: "New",
              value: "New",
            },
            {
              color: "primary",
              icon: "ServerIcon",
              statistic: res.data.active,
              statisticTitle: "Active",
              value: "Active",
            },
            {
              color: "primary",
              icon: "ServerIcon",
              statistic: res.data.paymentDue,
              statisticTitle: "Payment Due",
              value: "Payment Due",
            },
            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.complete,
              statisticTitle: "Completed",
              value: "Completed",
            },
            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.archived,
              statisticTitle: "Archived",
              value: "Archived",
            },
          ]
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
  },
}
</script>
